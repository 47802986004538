<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="table"
          title="공정 목록"
          tableId="table"
          :columns="grid.columns"
          :data="grid.data"
          :isExcelDown="false"
          :columnSetting="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-table
          ref="processTable"
          title="공정별 화학자재 취급량"
          tableId="processTable"
          :columns="gridprocess.columns"
          :data="gridprocess.data"
          :columnSetting="false"
          selection="multiple"
          rowKey="chemMaterialProcessId"
          :usePaging="false"
          :isExcelDown="false"
          :editable="editable"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="추가" v-if="editable && selectedProcessCd" icon="add" @btnClicked="addrowProcess" />
              <c-btn label="삭제" v-if="editable && selectedProcessCd" icon="remove" @btnClicked="removeRowProcess" />
              <c-btn label="저장" v-if="editable && selectedProcessCd" icon="save" @btnClicked="saveProcess" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-process-manage',
  data() {
    return {
      listUrl: '',
      detailUrl: '',
      processListUrl: '',
      processDeleteUrl: '',
      processSaveUrl: '',
      selectedProcessCd: '',
      editable: false,
      searchParam: {
        plantCd: null
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
          },
          {
            name: 'upProcessName',
            field: 'upProcessName',
            label: '상위공정',
            align: 'center',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '단위공정',
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '100%'
      },
      gridprocess: {
        columns: [],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.process.list.url;
      this.detailUrl = selectConfig.mdm.mam.chem.byprocess.url;
      this.processListUrl = selectConfig.mdm.mam.chem.process.url;
      this.processDeleteUrl = transactionConfig.mdm.mam.chem.process.delete.url;
      this.processSaveUrl = transactionConfig.mdm.mam.chem.process.insert.url;
      this.getList();
      
      this.$comm.getComboItems('VOLUME_UNIT').then(_result => {
        this.gridprocess.columns = [
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '제조 또는 사용여부',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            type: 'check',
            true: 'Y',
            false: 'N'
          },
          {
            name: 'usePurpose',
            field: 'usePurpose',
            label: '사용용도',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'dailyVolume',
            field: 'dailyVolume',
            label: '일 취급량',
            align: 'center',
            type: 'number',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'volumeUnit',
            field: 'volumeUnit',
            setHeader: true,
            required: false,
            label: '취급량 단위',
            align: 'center',
            style: 'width:100px',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
        ]
      });
    },
    rowRemoveSelect() {
      this.selectedProcessCd = '';
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        _table[0].classList.remove('bg-light-blue-1');
      }
    },
    getList() {
      this.rowRemoveSelect();
      this.gridprocess.data = [];
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.searchParam.processLevelCd = '20';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    rowClick(row) {
      this.selectedProcessCd = row.processCd;
      this.$http.url = this.detailUrl;
      this.$http.type = 'GET';
      this.$http.param = {processCd: this.selectedProcessCd};
      this.$http.request((_result) => {
        this.gridprocess.data = _result.data;
      },);
    },
    addrowProcess() {
      this.popupOptions.title = '화학자재 검색';
      this.popupOptions.param = {
        type: 'multiple',
        processLevelCd: '20'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/chemMaterialPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeProcess;
    },
    closeProcess(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.gridprocess.data, { mdmChemMaterialId: item.mdmChemMaterialId }) === -1) {
            this.gridprocess.data.push({
              chemMaterialProcessId: uid(),
              mdmChemMaterialId: item.mdmChemMaterialId,
              materialName: item.materialName,
              processCd: this.selectedProcessCd,
              processName: '',
              useFlag: 'N',
              usePurpose: '',
              dailyVolume: '',
              editFlag: 'C',
              volumeUnit: '',
            })
          }
        })
      }
    },
    saveProcess() {
      let saveData = this.gridprocess.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      if(saveData.length > 0) {
        let boolVolume = false;
        this.$_.forEach(saveData, item => {
          if (item.volumeUnit == '1' || item.volumeUnit == '2') {
            if (item.dailyVolume.length > 3) {
              boolVolume = true;
              return false;
            }
          }
        })
        if (boolVolume) {
          window.getApp.$emit('ALERT', {
            title: '안내 ', 
            message: '취급량 단위가 [㎏, ℓ],[g, ㎖] 일 경우에는\n\r일 취급량이 1000단위가 넘을 수가 없습니다.\n\r취급량 단위를 변경하세요.', /* 변경된 데이터가 없습니다. */
            type: 'warning', // success / info / warning / error
          });
        } else {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.processSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.rowClick({processCd: this.selectedProcessCd});
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내 ', 
          message: '변경된 데이터가 없습니다.',
          type: 'info', // success / info / warning / error
        });
      }
    },
    removeRowProcess() {
      let selectData = this.$refs['processTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.processDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.rowClick({processCd: this.selectedProcessCd});
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.gridprocess.data = this.$_.reject(this.gridprocess.data, item);
              })
              this.$refs['processTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
